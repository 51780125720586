// import { createWebHashHistory } from 'vue-router'; remove in production env
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Mutations } from '@/store/enums/StoreEnums';
import store from '@/store';

import AuthService from '@/core/services/AuthService';
import { checkAccess } from '@/core/helpers/access';
import { home } from '@/core/helpers/access';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/lsf/forwarding-service',
    component: () => import('@/layouts/main-layout/MainLayout.vue'),
    meta: {
      middleware: 'auth'
    },
    children: [
      {
        path: '/help',
        name: 'documentation',
        component: () => import('@/views/Documentation.vue'),
        meta: {
          pageTitle: '시스템 사용 매뉴얼',
          breadcrumbs: ['시스템 사용 매뉴얼']
        }
      },
      {
        path: '/user',
        name: 'self-mgmt',
        component: () => import('@/views/common/user/SelfMgmt.vue'),
        meta: {
          pageTitle: '내 계정 관리',
          breadcrumbs: ['내 계정 관리']
        }
      },
      {
        path: '/settings/printer',
        name: 'printer-mgmt',
        component: () => import('@/views/common/user/PrinterMgmt.vue'),
        meta: {
          pageTitle: '프린터 관리',
          breadcrumbs: ['사용환경 설정', '프린터'],
          access: [
            { system_id: 1, user_level_ids: [1, 2, 3, 4, 5, 6, 7] },
            { system_id: 2, user_level_ids: [1, 2, 3, 4, 5, 6, 7] }
          ],
          desktopOnly: true
        }
      },
      {
        path: '/mgmt/user',
        name: 'user-mgmt',
        component: () => import('@/views/common/user/UserMgmt.vue'),
        meta: {
          pageTitle: '사용자 관리',
          breadcrumbs: ['시스템 관리', '사용자 관리'],
          access: [
            { system_id: 1, user_level_ids: [1, 2, 3] },
            { system_id: 2, user_level_ids: [1, 2, 3] }
          ],
          desktopOnly: true
        }
      },
      {
        path: '/mgmt/center',
        name: 'center-mgmt',
        component: () => import('@/views/wms/center/CenterMgmt.vue'),
        meta: {
          pageTitle: '센터 관리',
          breadcrumbs: ['시스템 관리', '센터 관리'],
          access: [{ system_id: 2, user_level_ids: [1, 2, 3] }],
          desktopOnly: true
        }
      },
      {
        path: '/wms/inbound/shipping-service',
        name: 'inbound-checkin',
        component: () => import('@/views/wms/inbound/ShippingServiceCheckin.vue'),
        meta: {
          pageTitle: '배송대행 입고',
          breadcrumbs: ['WMS', '입하/입고 관리'],
          desktopOnly: true,
          access: [{ system_id: 2, user_level_ids: [1, 2, 3, 6, 7] }]
        }
      },
      {
        path: '/wms/outbound/outbound-location-history',
        name: 'outbound-location-history',
        component: () => import('@/views/wms/outbound/OutboundLocationHistoryMgmt.vue'),
        meta: {
          pageTitle: '출고 현황',
          breadcrumbs: ['WMS'],
          access: [{ system_id: 2, user_level_ids: [1, 2, 3, 6, 7] }]
        }
      },
      {
        path: '/wms/outbound/pda/:view',
        name: 'outbound-dashboard-mobile',
        component: () => import('@/views/wms/outbound/DashboardMobile.vue'),
        meta: {
          pageTitle: '출하 관리',
          breadcrumbs: ['WMS'],
          access: [{ system_id: 2, user_level_ids: [1, 2, 3, 6, 7] }],
          hideToolbar: true,
          mobileOnly: true
        }
      },
      {
        path: '/lsf/forwarding-service',
        name: 'forwarding-mgmt',
        component: () => import('@/views/lsf/forwarding-order/ForwardingMgmt.vue'),
        meta: {
          pageTitle: '배송대행',
          breadcrumbs: ['서비스'],
          access: [{ system_id: 1, user_level_ids: [1, 2, 3, 4, 5] }]
        }
      },
      {
        path: '/lsf/fulfillment',
        name: 'fulfillment-mgmt',
        component: () => import('@/views/lsf/fulfillment-order/FulfillmentMgmt.vue'),
        meta: {
          pageTitle: '상품 관리',
          breadcrumbs: ['클라이언트', '상품관리'],
          access: [{ system_id: 1, user_level_ids: [1, 2, 3, 4, 5, 10, 11] }]
        }
      },
      {
        path: '/lsf/stock-mgmt',
        name: 'stock-mgmt',
        component: () => import('@/views/lsf/stock/StockMgmt.vue'),
        meta: {
          pageTitle: '재고 관리',
          breadcrumbs: ['클라이언트', '재고관리'],
          access: [{ system_id: 1, user_level_ids: [1, 2, 3, 4, 5, 10, 11] }]
        }
      },
      {
        path: '/lsf/order-mgmt',
        name: 'order-mgmt',
        // component: () => import('@/views/lsf/order/OrderMgmt.vue'),
        component: () => import('@/views/lsf/order/OrderMgmtNew.vue'),
        meta: {
          pageTitle: '주문 관리',
          breadcrumbs: ['클라이언트', '주문관리'],
          access: [{ system_id: 1, user_level_ids: [1, 2, 3, 4, 5, 10, 11] }]
        }
      },
      {
        path: '/wms/inbound-mgmt',
        name: 'inbound-mgmt',
        component: () => import('@/views/wms/inbound/InboundMgmt.vue'),
        meta: {
          pageTitle: '입고 관리',
          breadcrumbs: ['WMS', '입고관리'],
          access: [{ system_id: 2, user_level_ids: [1, 2, 3, 4, 5, 6, 7, 10, 11] }]
        }
      },
      {
        // path: '/wms/picking-mgmt',
        // name: 'picking-mgmt',
        // component: () => import('@/views/wms/picking/PickingMgmt.vue'),
        //2024.07.25 MOD 김수호 : 기존 피킹관리는 상품 단위/랙 단위인데 주문 단위로 변경하면서 기존의 페이지 코드를 수정하기에는 상품 단위 로직을 유지는 해야하기에 새로 페이지를 만들어 라우터 수정
        path: '/wms/picking-mgmt',
        name: 'picking-mgmt',
        component: () => import('@/views/wms/picking/PickingMgmtByOrder.vue'),
        meta: {
          pageTitle: '피킹 관리',
          breadcrumbs: ['WMS', '피킹관리'],
          access: [{ system_id: 2, user_level_ids: [1, 2, 3, 6, 7] }]
        }
      },
      {
        path: '/wms/picking-process/:rack',
        name: 'picking-process',
        component: () => import('@/views/wms/picking/PickingProcess.vue'),
        meta: {
          pageTitle: '피킹 작업',
          breadcrumbs: ['WMS', '피킹작업'],
          access: [{ system_id: 2, user_level_ids: [1, 2, 3, 6, 7] }]
        }
      },
      {
        path: '/wms/packing-mgmt/packing-state',
        name: 'packing-state',
        // component: () => import('@/views/wms/packing/PackingState.vue'),
        //2024.08.12 MOD 김수호 : 기존 패킹관리는 상품 단위/랙 단위인데 주문 단위로 변경하면서 기존의 페이지 코드를 수정하기에는 상품 단위 로직을 유지는 해야하기에 새로 페이지를 만들어 라우터 수정
        component: () => import('@/views/wms/packing/PackingStateByOrder.vue'),
        meta: {
          pageTitle: '패킹 대기',
          breadcrumbs: ['WMS', '패킹대기'],
          access: [{ system_id: 2, user_level_ids: [1, 2, 3, 6, 7] }]
        }
      },
      {
        path: '/wms/packing-mgmt/packing-check',
        name: 'packing-check',
        // component: () => import('@/views/wms/packing/PackingCheck.vue'),
        //2024.08.12 MOD 김수호 : 기존 패킹관리는 상품 단위/랙 단위인데 주문 단위로 변경하면서 기존의 페이지 코드를 수정하기에는 상품 단위 로직을 유지는 해야하기에 새로 페이지를 만들어 라우터 수정
        component: () => import('@/views/wms/packing/PackingCheckByOrder.vue'),
        meta: {
          pageTitle: '패킹 검수',
          breadcrumbs: ['WMS', '패킹검수'],
          access: [{ system_id: 2, user_level_ids: [1, 2, 3, 6, 7] }]
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthLayout.vue'),
    children: [
      {
        path: '/sign-in',
        name: 'sign-in',
        component: () => import('@/views/common/authentication/SignIn.vue'),
        meta: {
          pageTitle: 'Sign In'
        }
      },
      {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/common/authentication/SignUp.vue'),
        meta: {
          pageTitle: 'Sign Up'
        }
      }
    ]
  },
  {
    path: '/',
    component: () => import('@/layouts/SystemLayout.vue'),
    children: [
      {
        // the 404 route, when none of the above matches
        path: '/unsupported',
        name: 'unsupportedDevice',
        component: () => import('@/views/common/landing-page/ErrorDevice.vue'),
        meta: {
          pageTitle: '미지원 디바이스'
        }
      },
      {
        // the 404 route, when none of the above matches
        path: '/404',
        name: '404',
        component: () => import('@/views/common/landing-page/Error404.vue'),
        meta: {
          pageTitle: 'Error 404'
        }
      },
      {
        path: '/500',
        name: '500',
        component: () => import('@/views/common/landing-page/Error500.vue'),
        meta: {
          pageTitle: 'Error 500'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // verify auth token before each page change
  AuthService.validateSession().then((authenticated) => {
    // Authentication Check for Pages that require Authenticaiton
    if (to.meta.middleware == 'auth') {
      if (authenticated) {
        if ('access' in to.meta) {
          if (checkAccess(to.meta.access)) {
            next();
          } else {
            home(router);
          }
        } else {
          next();
        }
      } else {
        next({ name: 'sign-in' });
      }
    } else {
      next();
    }
  });

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
});

export default router;
