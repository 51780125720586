<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent, nextTick, onMounted, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { Mutations, Actions } from '@/store/enums/StoreEnums';
import { themeMode } from '@/core/helpers/config';
import { initializeComponents } from '@/core/plugins/keenthemes';
import AuthService from '@/core/services/AuthService';

export default defineComponent({
  name: 'app',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const initializeAuth = async () => {
      try {
        // 현재 라우트가 인증이 필요한지 확인
        const requiresAuth = route.matched.some((record) => record.meta.requiresAuth);

        if (requiresAuth) {
          const isValid = await AuthService.validateSession();
          if (!isValid) {
            router.push({ name: 'sign-in' });
          }
        }
      } catch (error) {
        console.error('Auth initialization failed:', error);
        router.push({ name: 'sign-in' });
      }
    };

    onBeforeMount(async () => {
      // 앱 마운트 전에 인증 상태 초기화
      await initializeAuth();
    });

    onMounted(() => {
      /**
       * Overrides the layout config using saved data from localStorage
       * remove this to use static config (@/core/config/DefaultLayoutConfig.ts)
       */
      store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      /**
       *  Sets a mode from configuration
       */
      store.dispatch(Actions.SET_THEME_MODE_ACTION, themeMode.value);

      // TORA INITS
      store.dispatch(Actions.INIT_PRINTER);

      nextTick(() => {
        initializeComponents();
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading');
      });
    });
  }
});
</script>

<style lang="scss">
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~apexcharts/dist/apexcharts.css';
@import '~quill/dist/quill.snow.css';
@import '~animate.css';
@import '~sweetalert2/dist/sweetalert2.css';
@import '~nouislider/distribute/nouislider.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~socicon/css/socicon.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~dropzone/dist/dropzone.css';
@import '~@vueform/multiselect/themes/default.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~element-plus/dist/index.css';

// Main demo style scss
@import 'assets/sass/element-ui.dark';
@import 'assets/sass/element-ui.primary';
@import 'assets/sass/plugins';
@import 'assets/sass/style';

#app {
  display: contents;
}

.el-popup-parent--hidden {
  width: 100%;
  height: 100%;
  overflow: auto;
  max-width: none !important;
}

#kt_app_body {
  width: 100% !important;
}
</style>
